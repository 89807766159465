/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

@import "./../../../variables";

.Login {
  //padding-top: 5rem !important;
  flex: 1;
  display: flex
;
  .hero-login {
    // top: -6rem;
  }

  .hero::after {
    top: -6rem;
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    height: fit-content;
    z-index: 1;
    width: 55% !important;
    background: white;
    border-radius: 10px;
    color: $primary-color-dark !important;
    padding: 20px 10px;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    .container {
      width: 100% !important;
    }
  }

  .container h2 {
    color: $primary-color-dark !important;
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 40px;
  }

  .container p {
    color: $primary-color-dark !important;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 350px !important;
    padding: 20px;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
  }
  .input-group input {
    width: 100%;
    padding: 10px;
    border-radius: 8px !important;
    border: 1px solid #ccc;
  }

  #forgetpass {
    font-size: 1rem;
    margin-top: 5px;
  }
}
