/**
 * @title 
 * @author Beligent
 * @notice 
 * Copyright 2024
 */

@import "../../variables.scss";

.Solutions {
  flex: 1;

  // ---> Counterfeiting box
  .counterfeiting-box-outer {
    padding: 0 4rem 0 4rem;
    @media only screen and (max-width: $breakpoint-mobile) {
      padding: 0 1rem 0 1rem;
    }
  }

  .counterfeiting-box-inner {
    background: $primary-color-dark;
    color: #ffffff;
    padding: 3rem;
    transform: translateY(-4rem);
    border-radius: 10px;
  }

  .counterfeiting-box-inner h2 {
    font-weight: 700;
    color: #ffffff;
  }

  .counterfeiting-box-inner p {
    font-weight: 400;
    color: #ffffffcc;
  }

  .highlight-text {
    font-weight: 600;
    color: #ffffff;
  }

  // ---> Informations
  .informations-section {
    padding-left: 6rem;
    padding-right: 6rem;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .point-box h3 {
    font-size: 2.5rem;
    font-weight: 700;
  }

  .point-box p {
    line-height: 2.5rem;
  }

  .check {
    font-size: 4.5em;
  }

  .checked-points {
    display: flex;
  }

  // ---> Before footer box
  .text-section-blue-outer {
    padding: 0 4rem 2rem 4rem;
    @media only screen and (max-width: $breakpoint-mobile) {
      padding: 0 1.5rem 0 1.5rem;
    }
  }

  .text-section-blue-inner {
    background: $primary-color-light;
    color: #ffffff;
    padding: 3rem;
    border: 3px solid white;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    p {
      font-weight: 400;
      color: $primary-color-dark;
    }
  }

  // ---> Before footer contact us button
  .before-footer-button {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    h1 {
      margin-top: 0;
    }
  }

  .solution-hero::after {
    content: "";
    position: absolute;
    top: -14rem;
    right: 1em;
    width: 40%;
    height: 160%;
    background-image: url("../../../public/dna.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.63;
    z-index: 0;
    background-size: 120%;

    @media only screen and (max-width: 1515px) {
      top: -15rem;
      right: -4rem;
      width: 48%;
      height: 160%;
      background-size: 100%;
    }

    @media only screen and (max-width: 1400px) {
      top: -19rem;
      right: -14rem;
      width: 48%;
      height: 160%;
      background-size: 137%;
    }

    @media only screen and (max-width: 1000px) {
      right: -23em;
      background-size: 130%;
    }

    @media only screen and (max-width: $breakpoint-mobile) {
      display: none;
    }
  }

  .text-section-outer {
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 1rem;
  }

  .text-section-inner {
    padding: 2rem;
  }

  .highlight-text {
    font-weight: 600;
    color: #ffffff;
  }

  // ---> Informations
  .informations-section {
    padding: 0 6rem 4rem 6rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @media only screen and (max-width: $breakpoint-mobile) {
      padding: 0 1.5rem 0 1.5rem;
    }
  }

  .point-box h3 {
    font-size: 2.5rem;
    font-weight: 700;
  }

  .point-box p {
    line-height: 2.5rem;
  }

  .check {
    font-size: 4.5em;
    margin-top: -0.8rem;
  }
}
