/**
 * @title 
 * @author Beligent
 * @notice 
 * Copyright 2024
 */

.TermsOfUse {
  padding: 6em 2em 6em 4em;
  flex: 1;

  h2 {
    margin-top: 2rem;
  }
}
