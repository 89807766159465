/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

@import "../../../variables";

.KycForm {
  display: flex;
  flex-direction: column;
  padding-top: 2rem !important;

  .page-header {
    margin-bottom: 2rem;
  }

  .page-header p {
    line-height: 20px;
  }

  .go-back {
    color: white !important;
  }
  .go-back:hover {
    color: $contrast-color !important;
  }

  .container {
    width: 70%;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    .container {
      width: 100%;
    }
  }

  .kyc-form {
    width: 100%;
  }

  .form {
    width: 100%;
  }

  .dropzone-group {
    display: flex;
    flex-direction: column;
  }

  // ---> for file preview

  .image-preview {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 8px;
    margin: 2rem 0rem;
  }

  .image-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

  .close-button {
    position: absolute;
    top: 7px;
    right: 7px;
    background: white;
    border: none;
    padding: 8px;
    cursor: pointer;
    border-radius: 100%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pdf-preview {
    position: relative;
    width: 100%;
    height: 50px;
    border-radius: 8px;
    margin: 2rem 0rem;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .pdf-preview p {
    color: black !important;
  }

  .close-pdf-button {
    background: white;
    border: none;
    padding: 8px;
    cursor: pointer;
    border-radius: 100%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-5px);
  }
}
