/**
 * @title 
 * @author Beligent
 * @notice 
 * Copyright 2024
 */

.DropZone {
  .upload-container {
    height: 200px;
    text-align: center;
    margin: 2rem 0rem;
  }

  .drop-zone {
    width: 100%;
    height: 100%;
    margin: 0rem 0rem 3rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dotted rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: 12px;
    color: black !important;
    cursor: pointer;
    padding: 2rem;
    transition: background-color 0.3s ease;
  }

  .drop-zone:hover {
    background-color: #cfe2ff9b;
  }

  .drop-zone.active {
    background-color: #cfe2ff;
  }

  .drop-zone p {
    color: gray !important;
  }

  .icon {
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 8px;
  }

  .browse-text {
    color: #4da6ff;
    cursor: pointer;
  }
}
