/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

@import "../../../variables";

.AddEvent {
  // ---> passport-card
  padding: 4rem 3rem 4rem 3rem;

  .passport-container {
    width: 16rem;
    margin: 2rem 0rem;
  }

  // ---> Form styling
  .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
  }

  .inp-wrapper {
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
  }

  .inp-wrapper label {
    width: 30%;
  }
  .inp-wrapper input {
    width: 60%;
    padding-right: 10px;
    padding-left: 10px;
    border: 1px solid rgba(128, 128, 128, 0.593);
    height: 2.6rem;
  }
  .inp-wrapper input:focus {
    outline-color: $primary-color-light;
  }
}
