/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

.Dashboard {
  padding: 4rem 3rem 4rem 3rem;

  .outer-container {
    border-radius: 10px;
  }

  .passport-card {
    width: 300px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #333;
    background-color: #f8f9fa;
    border-radius: 8px;
    text-decoration: none;
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
      transform: scale(1.007);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    }

    .passport-icon {
      width: 55px;
      height: 55px;
    }

    .faded {
      opacity: 0.6;
    }

    .passport-text {
      color: #000;
      font-size: 14px;
      font-weight: 500;
      margin-top: 8px;
    }
  }

    @media (max-width: 1024px) {

      padding: 0rem;
      text-align: center;

      .outer-container{
        display: block !important;
        justify-items: space-between !important;
      }

      .outer-container>*:last-child {
        margin-top: 10px;
      }
    }
}
