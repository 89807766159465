/**
 * @title 
 * @author Beligent
 * @notice 
 * Copyright 2024
 */

@import "../../variables.scss";

.About {
  flex: 1;
  position: relative;
  font-family: "Rubik", sans-serif;

  // ---> Top Hero Section

  // ---> Context box
  .context-box-outer {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .context-box-inner {
    background: $primary-color-dark;
    color: #ffffff;
    padding: 3rem;
    transform: translateY(-4rem);
    border-radius: 10px;
  }

  .context-box-inner p {
    font-weight: 400;
    color: #ffffffcc;
  }

  // ---> Content
  .content-section {
    padding-left: 10rem;
    padding-right: 10rem;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .content-box p {
    line-height: 2rem;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    .contact-button {
      margin-bottom: 3rem;
    }
    .context-box-outer {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    .context-box-inner {
      padding: 2rem;
    }

    .content-section {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}
