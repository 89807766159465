/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

@import "./../../../variables";

.Onboarding {
  flex: 1 1;
  display: flex;

  .hero {
    padding: 0 0 0 0;
    flex: 1 1;
    @media only screen and (max-width: $breakpoint-mobile) {
      padding: 4em 2em 6em 2em;
      flex-direction: column;
    }
  }

  .hero {
    display: block;
  }
  .hero::after {
    top: 9rem !important;
  }

  .page-header {
    padding: 5rem 5rem 3rem 5rem;
  }

  .onboarding-text {
    padding: 0 1em 2em 1em;
    @media only screen and (max-width: $breakpoint-mobile) {
      padding: 0 1em 2em 1em;
    }
  }

  .container {
    display: flex;
    justify-content: center;
    margin: 0 auto 4rem auto;
    align-items: center;
    vertical-align: middle;
    position: relative;
    z-index: 1;
  }
  form {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: white;
  }
  .input-group {
    margin-bottom: 15px;
  }
  input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  .error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
}
