/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

@import "./../../../variables";

.ResetPassword {
  padding-top: 10rem !important;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    z-index: 1;
    height: fit-content;
    width: 55% !important;
    background: white;
    border-radius: 10px;
    color: $primary-color-dark !important;
    
    h2 {
      color: $primary-color-dark !important;
      margin-bottom: 1rem;
      font-size: 40px;
    }
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    .container {
      width: 100% !important;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    width: 350px !important;
    padding: 20px;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
  }

  .input-group input {
    width: 100%;
    padding: 10px;
    border-radius: 8px !important;
    border: 1px solid #ccc;
  }
}
