/**
 * @title 
 * @author Beligent
 * @notice 
 * Copyright 2024
 */

@import "../../variables";

.HomePage {
  flex: 1;

  //////// Advantages ////////

  #advantages-section {
    margin: 0em 0em 0em 0em;
    padding: 4em 0em 0em 0em;
    background-color: #ffffff;
  }
  .advantages-container {
    margin: 0em 0em 0em 0em;
    padding: 0em 4em 0em 4em;

    @media only screen and (max-width: $breakpoint-mobile) {
      padding: 0em 0em 0em 0em;
    }
  }

  p.advantages-subtitle {
    text-align: center;
    display: block;
    margin: 0 auto;
    max-width: 90%;
    padding: 20px 20px;
    margin-bottom: 0;
    font-size: 32px;
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .advantages.column {
    display: flex;
    flex-direction: column;
    flex-basis: 90%;
    flex: 1;
    justify-content: flex-start;
    height: 100%;
  }

  ul.advantages {
    padding-left: 20px;
    color: #2e54a7;
    margin: 0;
    @media only screen and (max-width: $breakpoint-mobile) {
      padding: 0em 0em 0em 0em;
    }
  }

  .advantages-list {
    list-style: none;
    text-align: left;
    flex-grow: 1;
    display: block;
    flex-direction: column;
    justify-content: space-between;
    min-width: 300px;
    max-width: 90%;
    margin: 0 auto;
    box-sizing: border-box;
  }

  li.advantages-list {
    display: block;
    min-height: 10rem;
    box-shadow: 0px 10px 10px #00000029;
    border: 2px solid #ffffff;
    border-radius: 8px;
    opacity: 1;
    padding: 10px 10px;
    max-width: 90%;
    list-style-position: outside;
    margin: 5px;
    @media only screen and (max-width: $breakpoint-mobile) {
      max-width: 100%;
    }
  }

  .advantages-list-description {
    text-align: left;
    font-size: 1.125rem;
    max-width: 100%;
    line-height: 1.5em;
    margin: 0.5em 1.5em 0.5em 1.5em;
  }

  .advantages-button {
    display: block;
    margin: 0 auto;
    width: 200px;
    height: 70px;
    font-size: 24px !important;
    font-weight: bold;
    background: #ff9300;
    box-shadow: 0px 6px 6px #00000029;
    border: 1px solid #ff9300;
    border-radius: 8px;
    opacity: 1;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 50px;
    color: white;
    transition: all 150ms ease-in-out;
    cursor: pointer;
  }

  .advantages-button:hover {
    background-color: #ff9300;
    border: 1px solid white;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
    transform: scale(1.01);
    transition: all 150ms ease-in-out;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    #advantages-section {
      margin: 0 0 0 0;
      padding: 3em 1em 0em 1em;
    }

    p.advantages-subtitle {
      font-size: 24px;
    }

    .advantages.column {
      width: 100%;
      align-items: center;
      margin-bottom: 30px;
    }

    .advantages-list {
      width: 100%;
      min-width: 300px;
      max-width: 500px;
      margin: 0 auto;
    }

    .advantages-button {
      margin-top: -20px;
      margin-bottom: 70px;
    }
  }

  ///////How it works///////

  .howitworks-section {
    margin: 0em 0em 0em 0em;
    padding: 5em 3em 5em 3em;
    font-family: "Rubik", sans-serif;
    background-color: #ffffff;

    @media only screen and (max-width: $breakpoint-mobile) {
      margin: 0em auto 0em auto;
      padding: 4em 1em 4em 1em;
    }
  }

  .howitworks-container {
    text-align: center;
    margin: 0em 0em 0em 0em;
    padding: 7em 2em 7em 3sem;
    justify-content: space-between;
    align-items: stretch;
    min-width: 20em;
  }

  .howitworks-headline {
    font-size: 52px !important;
  }

  p.howitworks-subtitle {
    text-align: center;
    display: block;
    margin: 0 auto;
    max-width: 90%;
    padding: 20px 20px;
    margin-bottom: 30px;
    font-size: 32px;
  }

  .step-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
  }

  .step-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: #2e54a7;
    font-size: 22px;
    text-align: left;
    padding: 10px;
    @media only screen and (max-width: $breakpoint-mobile) {
      padding: 0px;
    }
  }
  .step-icon {
    img {
      width: 50px;
      height: 50px;
    }
  }
  .step-line {
    min-width: 2px;
    height: 80px;
    background-color: #272795;
    margin: 0 30px;
    opacity: 0.5;
    @media only screen and (max-width: $breakpoint-mobile) {
      display: none;
    }
  }

  .step-title {
    font-size: 32px;
    font-weight: bold;
    text-align: left;
  }

  .step-text {
    flex-grow: 1;
    margin-left: 30px;
  }

  .step-description {
    max-width: 90%;
  }

  .step-number span {
    font-size: 48px;
    color: rgba(0, 0, 0, 0.1);
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .howitworks-button {
    display: block;
    margin: 0 auto;
    width: 200px;
    height: 70px;
    font-size: 24px !important;
    font-weight: bold;
    background: #ff9300;
    box-shadow: 0px 6px 6px #00000029;
    border: 1px solid #ff9300;
    border-radius: 8px;
    opacity: 1;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 20px;
    color: white;
    transition: all 150ms ease-in-out;
    cursor: pointer;
  }

  .howitworks-button:hover {
    background-color: #ff9300;
    border: 1px solid white;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
    transform: scale(1.01);
    transition: all 150ms ease-in-out;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    .step-container {
      align-items: center;
    }

    .howitworks-container {
      margin: 10px 10px;
    }
    .howitworks-headline {
      font-size: 32px !important;
    }
    p.howitworks-subtitle {
      font-size: 24px;
    }

    .step-box {
      width: 90%;
      padding: 10px;
    }

    .step-text {
      margin-left: 10px;
    }

    .hide-below-768 {
      display: none;
    }
  }

  .block-spacing {
    padding: 0rem 1rem 0rem 1rem;
    width: 33%;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    .block-spacing {
      padding: 1rem 0 1rem 0;
      width: 100%;
      p {
        width: 70%;
        margin: 1rem auto;
      }
    }
  }
  @media only screen and (min-width: 1100px) {
    .block-spacing {
      padding: 0rem 1rem 0rem 1rem;
    }
  }

  //////// CASE STUDY SECTION ////////
  .case-study {
    width: 100%;
    background-color: #ffffff;
    @media only screen and (max-width: $breakpoint-mobile) {
      padding: 3rem 0rem 3rem 0rem;
    }
    .case-study-container {
      margin: 0em auto 0em auto;
      padding: 5em 0em 5em 0em;
      max-width: 75%;
      min-width: 20em;

      @media only screen and (max-width: $breakpoint-mobile) {
        max-width: 90%;
      }
    }

    p.case-study-subtitle {
      text-align: center;
    }

    .case-study-content {
      padding: 2rem 3rem 2rem 3rem;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 8px;
      opacity: 1;
      @media only screen and (max-width: $breakpoint-mobile) {
        padding: 1.5rem 2rem 1.5rem 2rem;
      }
    }
    .column {
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      flex: 1.5 1;
    }
    .case-study-title {
      width: 150%;
      text-align: left;
    }
    .case-study-text-container {
      display: flex;
      @media only screen and (max-width: $breakpoint-mobile) {
        display: block;
      }
    }

    .case-study-text {
      flex-grow: 2;
      margin-top: -2rem;
    }

    .case-study {
      width: 100%;
      text-align: left;
    }

    .violin-container {
      flex-grow: 1;
    }

    .learn-more-button {
      background-color: #ff9300;
      border: 1px solid #ff9300;
      font-size: 20px;
      font-weight: 700;
      border-radius: 8px;
      box-shadow: 0px 6px 6px #00000029;
      width: 200px;
      height: 70px;
      color: white;
      margin-left: 0;
    }
    .luthier-logo {
      display: block;
      margin: 1rem 0rem 2rem 1rem;
      width: 25%;
      @media only screen and (max-width: $breakpoint-mobile) {
        width: 80%;
      }
    }
    .certified {
      width: 30%;
      margin: 0rem 1rem 0rem 0rem;
      align-self: end;
      @media only screen and (max-width: $breakpoint-mobile) {
        width: 60%;
      }
    }

    .violin {
      max-width: 80%;
      margin-bottom: 0;
      margin: 3rem 0rem 0rem 0rem;
      align-self: end;
    }

    @media only screen and (max-width: $breakpoint-mobile) {
      .case-study-container {
        text-align: center;
      }

      .column {
        text-align: center;
      }

      .case-study-container {
        padding: 5px;
      }

      .learn-more-button {
        max-width: 50%;
        padding: 5px;
        text-align: center;
        margin: 10px;
      }
    }
  }

  //////// CONTACT SECTION ////////
  .contact-section {
    margin: 0em 0em 0em 0em;
    padding: 5em 5em 5em 5em;
    font-family: "Rubik", sans-serif;
    text-align: center;
    @media only screen and (max-width: $breakpoint-mobile) {
      padding: 2em 1em 2em 1em;
    }
  }
}
