/**
 * @title 
 * @author Beligent
 * @notice 
 * Copyright 2024
 */

@import "../../variables.scss";

.CaseStudy {
  flex: 1;
  position: relative;
  font-family: "Rubik", sans-serif;

  // ---> Top Hero Section
  h1 {
    margin-top: 60px;
  }

  .hero .text-box {
    z-index: 1;
  }
  .luthier-logo {
    position: absolute;
    top: 70px;
    @media (max-width: 1100px) {
      display: none;
    }
  }

  .certified-img {
    position: absolute;
    top: 5rem;
    height: 250px;
    width: 200px;
    right: 5rem;
    z-index: 3;
    @media (max-width: 1100px) {
      top: 4rem;
      right: 6rem;
    }
    @media (max-width: 1000px) {
      display: none;
    }
  }
  .violin-container {
    z-index: 3;
    transform: scaleX(-1);
    margin-right: -2rem;
    @media (max-width: 1100px) {
      margin-top: 5rem;
      margin-right: -4rem;
    }
    @media (max-width: 1000px) {
      display: none;
    }
  }

  // ---> Context box
  .context-box-outer {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .context-box-inner {
    background: $primary-color-dark;
    color: #ffffff;
    padding: 3rem;
    transform: translateY(-4rem);
    border-radius: 10px;
  }
  .context-box-inner h2 {
    font-weight: 700;
    color: #ffffff;
  }
  .context-box-inner p {
    font-weight: 400;
    color: #ffffffcc;
  }
  .highlight-text {
    font-weight: 600;
    color: #ffffff;
  }

  // ---> Points
  .points-section {
    padding-left: 6rem;
    padding-right: 6rem;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .point-box h3 {
    font-size: 2.5rem;
    font-weight: 700;
  }
  .point-box p {
    line-height: 2.5rem;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    h1 {
      margin-top: 0;
    }
    .luthier-logo {
      visibility: hidden;
    }
    .contact-button {
      margin-bottom: 3rem;
    }
    .context-box-outer {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    .context-box-inner {
      padding: 2rem;
    }

    .points-section {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}
