/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

.PassportCollection {
  padding: 4rem 3rem 4rem 3rem;

  /* Gallery container */
  .gallery-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 25px;
    justify-content: center;
    padding: 20px;
  }

  .gallery-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 25px;
    justify-content: center;
    padding: 20px;
  }

    /* Loading and error messages */
    .loading,
    .error-message {
      text-align: center;
      font-size: 16px;
      margin-top: 20px;
    }
  
    .error-message {
      color: red;
    }

}
