/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

.ContactButton {
  .button-container {
    margin: 3rem 0 2rem 0;
    display: flex;
  }

  .confirmation {
    margin: 1rem 0 0 0;
  }
  .contact-button {
    height: 3.5rem;
  }
}
