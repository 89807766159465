/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

@import "../../../variables";

.RegistrationEnd {
  width: 100%;

  .end-registration-form h3 {
    color: $primary-color !important;
  }
  .page-header p {
    line-height: 20px;
  }

  .nav-item {
    color: white;
  }

  .container {
    width: 70%;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    .container {
      width: 100%;
    }
  }

  .end-registration-form {
    width: 100%;
  }

  .form {
    width: 100%;
  }

  .check-group {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 1rem;
    margin: 0.6rem 0;
    margin-bottom: 2rem;
  }

  .check-input {
    width: 1rem;
    height: 1rem;
  }

  .input-group select {
    width: 100%;
    padding: 10px;
    border-radius: 8px !important;
    border: 1px solid #ccc;
    margin-top: 6px;
  }
}
