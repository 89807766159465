@import "../../../variables.scss";

.AdminDashboard {
  padding: 3rem 2rem 4rem 2rem;
  flex: 1;

  h2 {
    color: $primary-color-dark;
    text-align: center;
  }

  .kyc-approval-container {
    font-family: $font-stack;
    background-color: $alternative-color;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin: 3rem auto;

    .message {
      text-align: center;
      color: $contrast-color;
      font-weight: bold;
    }

    .kyc-list {
      margin-top: 20px;

      table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        border-radius: 10px; // Adjust the value as needed
        overflow: hidden; // Ensures rounded corners apply correctly
        background: $alternative-color;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      }

      tr:last-child td {
        border-bottom: none;
      }

      table thead tr:first-child th:first-child {
        border-top-left-radius: 10px;
      }

      table thead tr:first-child th:last-child {
        border-top-right-radius: 10px;
      }

      table tbody tr:last-child td:first-child {
        border-bottom-left-radius: 10px;
      }

      table tbody tr:last-child td:last-child {
        border-bottom-right-radius: 10px;
      }

      th,
      td {
        padding: 10px;
        text-align: left;
        border-bottom: 1px solid #ddd;
        background: #f9f9f9;
      }

      th {
        background-color: $primary-color;
        color: $alternative-font-color;
      }

      .verify-head {
        text-align: center;
      }
    }

    .verify-button {
      background-color: $primary-color-dark;
      color: $alternative-color;
      border: none;
      padding: 8px 12px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      transition: background 0.3s;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;

      &:hover {
        background-color: $primary-color;
      }

      &:disabled {
        background-color: gray;
        cursor: not-allowed;
      }
    }

    .no-user {
      height: 20rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
