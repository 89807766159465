/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

@import "../../variables";

.AppFooter {
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 2em 2em 2em 2em;
  width: 100%;
  font-size: 1.375rem;
  border-top: #aaa solid 1px;
  background-color: #ffffff;

  margin: 0 auto;
  align-items: baseline;

  h4 {
    padding: 0 1rem 0 1rem;
  }

  @media (max-width: $breakpoint-mobile) {
    padding: 3rem 1em 2rem 1em;
  }
  .normal-layout {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    @media (max-width: 900px) {
      flex-direction: column;
    }
    .right-side {
      display: flex;
      align-items: center;
    }
    .company-section,
    .logo-container {
      display: inline-block;
    }
  }

  img {
    display: block;
    width: 100px;
    margin: 0.5rem 0rem 0rem 1rem;
    max-width: none;
  }

  .footer-left {
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: flex-end;
    padding-right: 5%;
    font-size: 0.9em;
    @media (max-width: 1300px) {
      padding-right: 2%;
    }
    @media (max-width: 1100px) {
      width: 33%;
    }
    @media (max-width: 1000px) {
      padding-right: 0;
    }
    @media (max-width: 900px) {
      width: 100%;
      justify-content: center;
    }
  }

  .logo {
    margin-right: 10px;
  }

  .text {
    display: flex;
    flex-direction: column;
    text-align: right;
  }

  .footer-right {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: space-evenly;
    font-size: 0.8em;
    padding: 0 5% 0 5%;
    @media (max-width: 1300px) {
      padding: 0 5% 0 4%;
    }
    @media (max-width: 1000px) {
      padding: 0 0 0 1em;
    }
    @media (max-width: 1100px) {
      width: 60%;
    }
    a {
      font-weight: 300 !important;
      font-size: 1em;
    }
  }

  .section {
    display: block;
  }

  .section h4 {
    margin-bottom: 5px;
  }

  .vertical-bar {
    width: 1px;
    background-color: #666666;
    align-self: stretch;
  }

  /* Mobile styles */
  @media (max-width: 768px) {
    .footer {
      flex-direction: column;
      align-items: center;
    }

    .footer-left,
    .footer-right {
      width: 100%;
      text-align: center;
    }

    .vertical-bar {
      display: none;
    }

    .footer-right {
      flex-direction: column;
      align-items: center;
    }

    .section {
      margin: 1em 0 1em 0;
    }
  }
}
