/**
 * @title 
 * @author Beligent
 * @notice 
 * Copyright 2024
 */

@import "../../variables.scss";

.ContactUs {
  flex: 1;
  position: relative;
  font-family: "Rubik", sans-serif;

  // ---> Top Hero Section
  h1 {
    margin-top: 60px;
  }
  .luthier-logo {
    position: absolute;
    top: 70px;
  }
  .certified-img {
    position: absolute;
    top: 70px;
    height: 250px;
    width: 200px;
  }

  // ---> Before footer box
  .text-section-outer {
    margin: 2rem 0 2rem 0;
    padding: 0 4rem 0 4rem;

    @media only screen and (max-width: $breakpoint-mobile) {
      margin: 1rem 0 1rem 0;
      padding: 0.5rem 1rem 0.5rem 1rem;
    }
  }

  .text-section-inner {
    h2 {
      margin-bottom: 2rem;
    }
  }

  .form-section {
    margin: -1rem 8rem 4rem 8rem;
    padding: 2rem 3rem 2rem 3rem;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    opacity: 1;
    @media only screen and (max-width: $breakpoint-mobile) {
      margin: 1rem 1rem 3rem 1rem;
      padding: 1.5rem 2rem 1.5rem 2rem;
    }

    h2 {
      margin-bottom: 2rem;
    }
  }

  .form-group {
    display: flex;
    margin-bottom: 15px;
  }

  .form-group input {
    width: 30%;
    padding: 8px;
    margin-top: 5px;
  }

  .form-group span {
    font-size: 18px;
    margin-top: 2px;
    width: 10rem;
    min-width: 6rem;
  }

  .form-group textarea {
    padding: 8px;
    margin-top: 5px;
  }

  @media only screen and (max-width: $breakpoint-medium) {
    .form-group input {
      width: 50%;
    }
  }
  @media only screen and (max-width: $breakpoint-mobile) {
    .form-group {
      display: block;
    }

    .form-group input {
      width: 100%;
      padding: 4px;
    }

    .form-group textarea {
      width: 100%;
      height: 115px;
    }
  }
}
