/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

@import "../../../variables.scss";

.UserSettings {
  padding: 4rem 3rem 4rem 3rem;

  .profile-settings {
    display: flex;
    color: #333;
    min-height: 100vh;

    @media (max-width: 1024px) {
      display: block;
    }

    .sidebar {
      width: 350px;
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      padding: 20px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      text-align: left;

      @media (max-width: 1024px) {
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0px;
      }

      .profile-icon {
        width: 180px;
        height: 180px;
        background: no-repeat center;
        background-size: cover;
        margin-bottom: 20px;
      }

      .menu {
        width: 100%;
        list-style: none;
        padding: 0;
        margin-top: 10px;

        span {
          color: $primary-color-dark;
        }

        .menu-item {
          background: rgba(255, 255, 255, 0.5);
          margin: 12px auto;
          border-radius: 6px;
          transition: all 0.3s ease;
          padding-left: 5px;
          font-size: medium;

          &:hover {
            background: white;
            cursor: pointer;
          }
        }
      }
    }

    .content {
      flex: 1;
      padding: 3rem;

      h2 {
        font-size: 24px;
        font-weight: 600;
        color: #333;
      }

      .description {
        margin-bottom: 3rem;
      }

      section {
        margin-bottom: 25px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ddd;
      }

      h3 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
        color: #333;
      }

      .email-input {
        display: flex;
        gap: 10px;
        margin-top: 10px;

        input {
          flex: 1;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 6px;
          font-size: 14px;
          outline: none;
          transition: 0.3s;

          &:focus {
            border-color: #007bff;
            box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
          }
        }

        button {
          background: #ffcc00;
          border: none;
          padding: 10px 15px;
          cursor: pointer;
          border-radius: 6px;
          font-weight: 600;
          transition: all 0.3s ease;
          font-size: 14px;

          &:hover {
            background: #e6b800;
          }
        }
      }

      .password-btn {
        background: #ffcc00;
        border: none;
        padding: 10px 15px;
        cursor: pointer;
        border-radius: 6px;
        font-weight: 600;
        font-size: 14px;
        transition: all 0.3s ease;

        @media (max-width: 1024px) {
          margin-top: 10px;
        }

        &:hover {
          background: #e6b800;
        }
      }

      .other-changes {
        border: none;
        margin-top: 20px;
        text-align: center;

        a {
          font-weight: 600;
          color: #007bff;
          text-decoration: none;
          transition: 0.3s;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
