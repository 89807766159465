/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

@import "../../../variables";

.PassportCreation {
  padding: 4rem 3rem 4rem 3rem;

  .passport-form {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  .form-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .inp-wrapper {
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
  }

  .inp-wrapper label {
    width: 30%;
  }
  .inp-wrapper input {
    width: 60%;
    padding-right: 10px;
    padding-left: 10px;
    border: 1px solid rgba(128, 128, 128, 0.593);
  }
  .inp-wrapper input:focus {
    outline-color: $primary-color-light;
  }

  .inp-wrapper select {
    width: 60%;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 5px;
    height: 2.6rem;
    border: 1px solid rgba(128, 128, 128, 0.593);
    outline-color: $primary-color-light;
  }

  /* For Webkit browsers (Chrome, Safari) */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* For Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  // ---> for file preview

  .image-preview {
    position: relative;
    width: 250px;
    height: 250px;
    border-radius: 8px;
    margin: 2rem 0rem;
  }

  .image-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

  .close-button {
    position: absolute;
    top: 7px;
    right: 7px;
    background: white;
    border: none;
    padding: 8px;
    cursor: pointer;
    border-radius: 100%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
