/**
 * @title 
 * @author Beligent
 * @notice 
 * Copyright 2024
 */

@import "./../../../variables";

.Sidebar {
  min-width: 60px;
  padding: 3rem 2rem;

  .menu-item {
    display: flex;
    align-items: center;
    padding: 0.5em 0.5em 0.5em 0.5em;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
    color: #333;
    text-decoration: none;
    border-radius: 4px;
    margin: 5px 10px;

    @media only screen and (max-width: $breakpoint-mobile) {
      padding: 0.15em 0.5em 0.15em 0.5em;
      font-size: 1rem;
    }
  }

  .icon {
    font-size: 24px;
    margin-right: 15px;
    color: #666;
  }

  .just-title {
    font-weight: 400;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  /* Mobile Styles */
  @media (max-width: 768px) {
    .sidebar {
      width: 60px; /* Collapsed */
    }
  }
}
