/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

@import "../../../variables.scss";

.SubscriptionPayment {
    display: flex;
    flex-direction: column;
    padding-top: 2rem !important;

    .page-header {
        margin-bottom: 2rem;
    }

    .page-header p {
        line-height: 20px;
    }

    .go-back {
        color: white !important;
    }

    .go-back:hover {
        color: $contrast-color !important;
    }

    .payment-container {
        display: flex;
        width: fit-content;
        align-self: center;
        font-family: Arial, sans-serif;
        border-radius: 10px;
        margin: 0 auto;
        z-index: 2;
    }

    .subscription-section {
        padding: 2rem;
    }

    .header {
        text-align: center;
    }

    .amount {
        // font-size: 2rem;
        font-weight: bold;
        margin-top: 3rem;
    }

    .details {
        justify-items: center;

        .details-header {
            display: flex;
        }

        .label {
            font-weight: bold;
            margin-right: 1rem;
        }

        // .value {
        //     margin-bottom: 1rem;
        // }

        .placeholder {
            background: rgba(255, 255, 255, 0.3);
            height: 50px;
            border-radius: 5px;
            margin: 0.5rem 0;
        }
    }

    .card-container {
        display: flex;
        gap: 20px;
        justify-content: center;
    }

    .subscription-card {
        background: #460000;
        border: 1px solid #ddd;
        border-radius: 12px;
        padding: 20px;
        text-align: center;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease-in-out;
        width: 250px;
    }

    .subscription-card:hover {
        transform: scale(1.05);
    }

    .plan-name {
        font-weight: bold;
        font-size: 1.2em;
        margin-bottom: 10px;
    }

    .plan-period {
        font-size: 0.9em;
        color: #666;
        margin-bottom: 10px;
    }

    .plan-feature {
        font-size: 0.9em;
        margin: 5px 0;
    }

    .plan-price {
        font-size: 1.3em;
        font-weight: bold;
        margin: 15px 0;
    }

    h3 {
        margin-bottom: 1rem;
    }


    .payment-methods {
        display: flex;
        gap: 0.5rem;
        margin-bottom: 1rem;
        flex-wrap: wrap;

        .payment-icon {
            background: #f5f5f5;
            border: none;
            padding: 1rem;
            border-radius: 5px;
            cursor: pointer;
        }
    }

    .payment-form {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        input {
            padding: 0.75rem;
            border: 1px solid #ccc;
            border-radius: 5px;
        }

        .card-details {
            display: flex;
            gap: 1rem;
        }

        .save-card {
            input {
                height: 15px;
                width: 15px;
            }

            display: flex;
            gap: 0.5rem;
            color: black
        }

        .pay-button {
            background: $primary-color-dark;
            color: white;
            padding: 1rem;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 1rem;
            text-align: center;
        }

        .pay-button:hover {
            background: $primary-color;
            transition: ease-in-out 0.1s;
        }
    }
}