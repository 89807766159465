/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

/* Card styling */
.PassportPreview {
  background: white;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
  max-width: 320px;
  margin: auto;

  &:hover {
    transform: scale(1.007);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }

  /* Image styling */
  .image {
    width: 100%;
    object-fit: cover;
    border-bottom: 1px solid #ddd;
  }

  /* image-card */
  .image-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  /* Image details */
  .image-details {
    padding: 15px;
    text-align: left;
  }

  /* Title */
  .image-title {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
  }

  /* Description */
  .image-description {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
  }

  /* Date */
  .image-date {
    font-size: 12px;
    color: #888;
  }

  .image-actions {
    display: flex;
    justify-content: space-around;
    gap: 8px;
    margin-top: 10px;
  }

  .icon {
    height: 2.5rem;
    width: 2.5rem;
  }

  .action-btn {
    border: none;
    background: none;
    font-size: 18px;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      transform: scale(1.07);
      opacity: 0.8;
    }
  }
  /* Loading and error messages */
  .loading,
  .error-message {
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
  }

  .error-message {
    color: red;
  }
}
