/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

@import "../../../variables";

.AddDocument {
  // ---> passport-card
  padding: 4rem 3rem 4rem 3rem;

  .passport-container {
    width: 16rem;
    margin: 2rem 0rem;
  }

  // ---> Form styling
  .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .inp-wrapper {
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
  }

  .inp-wrapper label {
    width: 30%;
  }
  .inp-wrapper input {
    width: 60%;
    padding-right: 10px;
    padding-left: 10px;
    border: 1px solid rgba(128, 128, 128, 0.593);
    height: 2.6rem;
  }
  .inp-wrapper input:focus {
    outline-color: $primary-color-light;
  }

  // ---> For file upload
  .upload-container {
    height: 250px;
    text-align: center;
    margin: 2rem 0rem;
    display: block;
  }

  .drop-zone {
    width: 100%;
    height: 100%;
    margin: 0rem 0rem 3rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 4px dotted rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: 12px;
    color: black;
    cursor: pointer;
    padding: 2rem;
    transition: background-color 0.3s ease;
  }

  .drop-zone:hover {
    background-color: rgba(0, 0, 0, 0.09);
  }

  .drop-zone.active {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .icon {
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 8px;
  }

  .browse-text {
    color: #4da6ff;
    cursor: pointer;
  }

  .doc-preview {
    display: flex;
    align-items: center;
    gap: 50px;
    width: 750px;
    height: 50px;
    border-radius: 8px;
    margin: 2rem 0rem;
  }

  .close-button {
    background: white;
    border: none;
    padding: 8px;
    cursor: pointer;
    border-radius: 100%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
