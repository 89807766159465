/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

@import "../../../variables";

.SubscriptionSelection {
  display: flex;
  flex-direction: column;
  padding-top: 2rem !important;

  .page-header {
    margin-bottom: 2rem;
  }

  .page-header p {
    line-height: 20px;
  }

  .go-back {
    color: white !important;
  }
  .go-back:hover {
    color: $contrast-color !important;
  }

  .subscription-container {
    width: 70%;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    .subscription-container {
      width: 100%;
    }
  }

  .subscription-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
    background: white;
    border-radius: 8px;
  }

  .subscription-header select {
    border: 1px solid #ccc;
    
  }

  .card-container {
    display: flex;
    gap: 20px;
    justify-content: center;
  }

  .subscription-card {
    background: white;
    border: 1px solid #ddd;
    border-radius: 12px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    width: 250px;
  }
  .subscription-card h3,
  .subscription-card p {
    color: $primary-color-dark !important;
  }

  .subscription-card:hover {
    transform: scale(1.05);
  }

  .plan-name {
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 10px;
  }

  .plan-period {
    font-size: 0.9em;
    color: #666;
    margin-bottom: 10px;
  }

  .plan-feature {
    font-size: 0.9em;
    margin: 5px 0;
  }

  .plan-price {
    font-size: 1.3em;
    font-weight: bold;
    margin: 15px 0;
  }

  .buy-button {
    background-color: #ffcc00;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
  }

  .buy-button:hover {
    background-color: #e6b800;
  }
}
