/**
 * @title 
 * @author Beligent
 * @notice 
 * Copyright 2024
 */

@import "../../../variables.scss";

.PassportViewer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .container {
    max-width: unset;
  }

  div.report-container {
    flex: 1; /* Pushes footer to the bottom */
    display: flex;
    flex-direction: column;

    margin: 0 auto;
    line-height: 1.4;
    letter-spacing: 0.2px;
    padding: 15px 8px 15px 17px;
    border-radius: 5px;
    font-size: 14px;

    overflow: auto;
  }

  div.passport-img {
    margin: 1em 0em 1em 0em;
    border: 1px solid #777;
    border-radius: 0.5em;
    box-shadow: 0.1em 0.1em 0.3em 0.1em rgba(0, 0, 0, 0.2);
    padding: 2em;

    svg,
    img {
      width: 100%;
      border: 1px solid #ddd;
    }
  }

  @media only screen and (max-width: 600px) {
    div.passport-img {
      border: 0px;
    }
    div.card {
      border: 0px;
    }
    .accordion-item {
      border: 0px;
    }
  }

  .report-header {
    display: flex;
    align-items: flex-start;

    .report-image {
      width: 35%;
    }
  }

  .partial-width {
    width: 96.5%;
  }

  div.report-general {
    padding: 0 2em;
    flex: 1;
  }

  .passport-title {
    font-size: 1.5em;
    font-weight: 600;
  }
  .passport-collection {
    font-size: 1.2em;
    font-weight: 500;
  }

  .row .mb-4 {
    margin-bottom: 0.5em !important;
  }

  .col-8 {
    color: $primary-color;
  }

  // overriding Boostrap
  .card {
    padding: 2em 2em 1em 2em;
    background-color: unset;
    margin: 1em 1em 1em 0em;
    border-color: #777;
    border-radius: 0.5em;
    box-shadow: 0.1em 0.1em 0.3em 0.1em rgba(0, 0, 0, 0.2);
    background-color: white;
  }

  .accordion {
    margin: 1em 1em 1em 0em;
    border-radius: 0.5em;
    box-shadow: 0.1em 0.1em 0.3em 0.1em rgba(0, 0, 0, 0.2);
  }
  .accordion-item,
  .accordion-item button {
    background-color: white;
    border-color: #777;
    border-radius: 0.5em;
  }
}
