/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

@import "../../variables";

.AppHeader {
  width: 100%;
  top: 0;
  left: 0;
  margin: 0 auto;
  position: relative;
  border-bottom: #aaa solid 1px;

  header {
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
    color: black;
    @media only screen and (max-width: $breakpoint-mobile) {
      text-align: end;
    }
    padding: 0 3em 0 3em;
    @media only screen and (max-width: $breakpoint-mobile) {
      padding: 0 1em 0 1em;
    }
  }
  .app-logo {
    top: 24px;
    height: 71px;
    display: block;
    position: absolute;
  }

  .logo-container {
    //margin-left: 3em;
    @media only screen and (max-width: $breakpoint-mobile) {
      margin-left: 1em;
    }
  }

  z-index: 10;

  ul {
    line-height: 2em;
  }

  li {
    margin: 0px 10px 0px 10px;
  }

  a {
    text-decoration-line: none;
    color: white;
  }
  a:hover,
  a:visited,
  a:active {
    color: white;
  }

  .brand-name {
    font-weight: 900;
    display: inline;
  }

  .connection-info {
    font-size: calc(6px + 0.5vmin);
    font-weight: 300;
  }

  header > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 118px;
    padding: 0px 1em 0px 1em;
  }

  .nav-item {
    line-height: 2.5rem;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    .logo-container img {
      height: 3em;
    }

    .app-logo {
      top: 17px;
    }
    header > div {
      height: 5em;
      padding: 0 0 0 0;
    }
  }
  .menu-spacer {
    flex-grow: 1;
  }

  .navbar-nav {
    align-items: center;
  }

  .menu-toggler {
    position: fixed;
    right: 1em;
    background-color: white;
    color: $primary-color-dark;
    padding: 10px 15px;
    cursor: pointer;
    border: 2px solid #aaa;
    border-radius: 8px;
    z-index: 1001;
    outline: none;

    opacity: 1;
    transform: scale(0.9);
    transition: opacity 0.6s ease-in, transform 0.3s ease-in;

    /* Show menu toggler on small screens */
    @media (max-width: 992px) {
      display: block;
    }
  }
  .menu-toggler:focus {
    outline: none;
  }
  .close {
    opacity: 0;
    transform: scale(1);
    transition: opacity 0.1s ease-out, transform 0.5s ease-out;
  }

  .slide-panel {
    position: fixed;
    top: 0;
    right: -300px; /* Hidden by default */
    width: 300px;
    height: 100%;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    transition: right 0.3s ease;
    z-index: 1000;
  }

  .panel-header {
    padding: 10px;
  }

  .close-btn {
    background-color: unset;
    color: $primary-color-dark;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border: 2px solid $primary-color-dark;
    border-radius: 8px;
  }

  .panel-menu {
    list-style: none;
    padding: 0;
    margin: 20px 0;
  }

  .panel-menu li {
    padding: 10px 20px;
  }

  .panel-menu li a {
    text-decoration: none;
    color: #333;
  }

  .panel-menu li a:hover {
    color: #007bff;
  }

  /* Sliding panel styles */
  .sliding-panel {
    position: fixed;
    top: 0;
    right: -10em; /* Hidden off-screen initially */
    width: 10em;
    height: 100%;
    backdrop-filter: blur(100px);
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    transition: right 0.3s ease-in-out;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .sliding-panel.open {
    right: 0; /* Slides into view */
  }

  /* Overriding */
  .LoginButton {
    margin: 0em 0em 1em 1em;

    @media only screen and (max-width: $breakpoint-mobile) {
      margin: 0em 0em 0em 0em;
    }
  }
}
